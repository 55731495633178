import { graphql } from "gatsby";
import * as React from "react";
import { WmkSeo } from "wmk-seo";
import { DocumentFields } from "../fragments/NodeDocumentFields";
import { ContentfulImageQuery } from "wmk-image";

const DocumentNode = ({
  data,
  pageContext
}: {
  pageContext: { slug: string };
  data: DocumentNodeQuery;
}) => {
  // console.log("data", data);
  // console.log("props", props)
  return (
    <>
      <WmkSeo.Meta
        title={data?.contentfulDocument?.title}
        siteTitle={data?.site?.siteMetadata?.title}
        baseUrl={data?.site?.siteMetadata?.baseUrl}
        slug={pageContext?.slug}
        path={"/docs/"}
        description={data?.contentfulDocument?.file?.description}
        ogImageUrl={data?.logo?.gatsbyImageData?.images?.fallback?.src}
        twitterImageUrl={data?.logo?.gatsbyImageData?.images?.fallback?.src}
        twitterHandle={data?.site?.siteMetadata?.twitter}
        isSiteTitleDisplayed
      />
      <object
        width="100%"
        data={data?.contentfulDocument?.file?.url}
        type="application/pdf"
        style={{ height: "100vh", minHeight: "100vh" }}>
        <iframe
          width="100%"
          style={{ height: "100vh", minHeight: "100vh" }}
          src={data?.contentfulDocument?.file?.url}>
          <p>Unfortunately, this browser does not support PDF rendering</p>
        </iframe>
      </object>
    </>
  );
};

export default DocumentNode;

export interface DocumentNodeQuery {
  contentfulDocument: DocumentFields;
  site: {
    siteMetadata: {
      description: string;
      baseUrl: string;
      title: string;
      twitter: string;
    };
  };
  logo: ContentfulImageQuery;
}

export const query = graphql`
  query documentQuery($slug: String) {
    contentfulDocument(slug: { eq: $slug }) {
      ...NodeDocumentFields
    }
    site {
      siteMetadata {
        baseUrl
        description
        title
        twitter
      }
    }
    logo: contentfulAsset(title: { eq: "Dipra Gradient Logo with Tag" }) {
      ...NodeImageFields
    }
  }
`;
